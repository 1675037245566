<template>
    <img v-if="currentyear == 0" src='@/assets/loading-buffering.gif' class='h-20 w-20 mx-auto mt-10' />

    <div v-if="currentyear > 0" class='w-full px-2 mx-auto mt-10 pb-20' style="width: 55%;">
        <div class="text-center">
            <h1 class='font-bold text-2xl hidden sm:inline-block'>JABATAN TANAH DAN UKUR SABAH, MALAYSIA</h1>

            <!-- <h2 class = 'hidden sm:block'>IBU PEJABAT JABATAN TANAH DAN UKUR NEGERI SABAH,</h2> -->
            <h2 class='hidden sm:block'>WISMA TANAH DAN UKUR,</h2>
            <h2 class='hidden sm:block'>JALAN PERWIRA, 88200 KOTA KINABALU, SABAH</h2>
            <h2 class='hidden sm:block'>TEL : 088-527603 TEL : 088-527604 TEL : 088-527618</h2>

            <h1 class='font-bold sm:text-lg'>{{ $t('arrearspage.noticetitle') }}</h1>
        </div>

        <div class="grid grid-cols-3 mt-10 mb-5 px-2 text-sm sm:text-base">
            <div class='col-span-3 font-bold'>{{ $t('titleno') }}: {{ titleno }}</div>
            <div class='col-span-3 font-bold text-right'>{{ $t('arrearspage.amount') }} (RM)</div>
            <div class='col-span-3'><hr style="border: 1px dashed black;"></div>
            <div class='col-span-2'>{{ $t('arrearspage.cfduemsg') }} 31-12-{{ currentyear - 1 }}</div>
            <div class='text-right'>{{ $n(cfdue, 'decimal') }}</div>

            <div class='text-red-500 col-span-2'>{{ $t('arrearspage.curduemsg') }} {{ currentyear }}</div>
            <div class='text-right text-red-500'>{{ $n(curdue, 'decimal') }}</div>

            <div class='col-span-2'>{{ $t('arrearspage.totalquitrentoutstanding') }}</div>
            <div class='text-right'>{{ $n(cfdue + curdue, 'decimal') }}</div>

            <template v-if="lastpaymentdate != '00-00-0000'">
                <div class='col-span-2'>{{ $t('arrearspage.cfpaymsg') }} {{ lastpaymentdate }}</div>
                <div class='text-right'>{{ $n(totalpaid, 'decimal') }}</div>
            </template>

            <div class='col-span-2'>{{ $t('arrearspage.needtopay') }}</div>
            <div class='text-right'>{{ $n(tobepaid, 'decimal') }}</div>

            <template v-if="totalpaidnoticefee > 0">
                <div class='col-span-2 mt-5'>{{ $t('arrearspage.cfnoticefeemsg') }}</div>
                <div class='text-right mt-5'>{{ $n(cfnoticefee, 'decimal') }}</div>
            </template>

            <template v-if="totalpaidnoticefee > 0">
                <div class='col-span-2'>{{ $t('arrearspage.cfpaynoticefeemsg') }} {{ lastpaymentdate }}</div>
                <div class='text-right'>{{ $n(totalpaidnoticefee, 'decimal') }}</div>
            </template>

            <template v-if="cfnoticefee - totalpaidnoticefee > 0">
                <div class='col-span-2'>{{ $t('arrearspage.noticefeeneedtopay') }}</div>
                <div class='text-right'>{{ $n(tobepaidnoticefee, 'decimal') }}</div>
            </template>

            <template v-if="totalpaidsurcaj > 0">
                <div class='col-span-2 mt-5'>{{ $t('arrearspage.cfsurcajmsg') }}</div>
                <div class='text-right mt-5'>{{ $n(cfsurcaj, 'decimal') }}</div>
            </template>

            <template v-if="totalpaidsurcaj > 0">
                <div class='col-span-2'>{{ $t('arrearspage.cfpaysurcajmsg') }} {{ lastpaymentdate }}</div>
                <div class='text-right'>{{ $n(totalpaidsurcaj, 'decimal') }}</div>
            </template>

            <template v-if="cfsurcaj - totalpaidsurcaj > 0">
                <div class='col-span-2'>{{ $t('arrearspage.surcajneedtopay') }}</div>
                <div class='text-right'>{{ $n(tobepaidsurcharge, 'decimal') }}</div>
            </template>

            <div class='col-span-2 mt-5' style="font-weight: bold;">{{ $t('arrearspage.totalamount') }}</div>
            <div class='text-right mt-5' style="font-weight: bold;">{{ $n(tobepaidoutstanding, 'decimal') }}</div>

            <div class="col-span-3 text-red-600 mt-3">
                ** {{ $t('arrearspage.discountmsg') }} **
            </div>

            <div v-if="!paymentmoduleenabled" class="col-span-3 text-red-600 mt-3">
              {{ paymentmoduledisablemessage }}
            </div>
        </div>

        <template v-if="paymentmoduleenabled">
            <IconButtonGreen v-if="tobepaid > 0 || tobepaidnoticefee > 0" @click="pay" :label="$t('makepaymentfpx')" :class="'float-right'"
              svg="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
        </template>

        <IconButton v-show="!showpayment" @click="showpayment = true" :label="$t('showpayment')"
            svg="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />

        <div v-show="showpayment" class='mb-5 bg-gray-100 rounded p-2 text-xs sm:text-base' style="margin-top: 70px;">
            <button @click="showpayment = false" class='float-right'>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd" />
                </svg>
            </button>
            <h1 class='font-bold'>{{ $t('arrearspage.rentdetails') }}</h1>
            <div class='grid grid-cols-2 p-2 sm:w-1/2'>
                <div class='font-bold'>{{ $t('year') }}</div>
                <div class='font-bold text-right'>{{ $t('arrearspage.rent') }} (RM)</div>

                <template v-for="qr in rents" :key="qr.rctnum">
                    <div>{{ qr.tyear }}</div>
                    <div class='text-right'>{{ $n(qr.amt, 'decimal') }}</div>
                </template>
            </div>
            <h1 class='font-bold'>{{ $t('arrearspage.paymentdetails') }}</h1>
            <div class='grid grid-cols-5 p-2'>
                <div class='font-bold'>{{ $t('arrearspage.recno') }}</div>
                <div class='font-bold'>{{ $t('arrearspage.date') }}</div>
                <div class='font-bold text-right'>{{ $t('arrearspage.rent') }} (RM)</div>
                <div class='font-bold text-right'>{{ $t('arrearspage.notice') }} (RM)</div>
                <div class='font-bold text-right'>{{ $t('arrearspage.surcharge') }} (RM)</div>

                <!-- <template v-for="p in payments" :key="p.rctnum">
                    <div>{{ p.rctnum }}</div>
                    <div>{{ p.tdate }}</div>
                    <div class='text-right'>{{ $n(p.amt, 'decimal') }}</div>
                </template> -->

                <template v-for="r in receipts" :key="r.rctnum">
                    <div>{{ r.rctnum }}</div>
                    <div>{{ r.tdate }}</div>
                    <div class='text-right'>{{ $n(r.quitrent, 'decimal') }}</div>
                    <div class='text-right'>{{ $n(r.noticefee, 'decimal') }}</div>
                    <div class='text-right'>{{ $n(r.surcharge, 'decimal') }}</div>
                </template>

                <!-- <template v-for="r in receipts" :key="r.rctnum">
                    <div v-if="r.rctnum.includes('FPX') || r.rctnum.includes('SSB') || r.rctnum.includes('ALL')"><a :href="this.receiptpdfurl + '/?rctnum=' + r.rctnum" 
                        target="_blank"
                        style="color:blue; text-decoration: underline;">{{ r.rctnum }}</a></div>
                    <div v-else>{{ r.rctnum }}</div>
                    <div>{{ r.tdate }}</div>
                    <div class='text-right'>{{ $n(r.amt, 'decimal') }}</div>
                </template> -->

                <div class='col-span-2 font-bold'>{{ $t('arrearspage.totalpayment') }}</div>
                <div class='text-right font-bold'>{{ $n(totalpaid, 'decimal') }}</div>
                <div class='text-right font-bold'>{{ $n(totalpaidnoticefee, 'decimal') }}</div>
                <div class='text-right font-bold'>{{ $n(totalpaidsurcaj, 'decimal') }}</div>
            </div>
            <div style="text-align: center;">
                <a :href="this.statementpdfurl + '/?titleno=' + this.titleno"
                    target="_blank" class = 'm-1 bg-blue-500 hover:bg-blue-700 rounded p-2 text-white text-sm'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
                {{ $t('arrearspage.downloadstatement') }} </a>
            </div>
        </div>

        

        <IconButton v-show="!showdetail" @click="showdetail = true" :label="$t('moredetail')"
            svg="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />

        <div v-show="showdetail" class='mb-5 bg-gray-100 rounded p-3 text-xs sm:text-base'>
            <button @click="showdetail = false" class='float-right'>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd" />
                </svg>
            </button>

            <p class='mt-5'>Sila jelaskan cukai tanah tuan/puan dengan segera dengan membuat pembayaran menggunakan kaedah seperti berikut:
            </p>

            <ul class='list-decimal pl-5'>
                <li class='mt-3'>
                    <p>BAYARAN SECARA ON-LINE MELALUI:</p>
                    <ol class='list-decimal pl-5' type="a">
                        <li>
                            <p>FPX</p>
                            <p>Melalui laman web <b><a href="https://jtu.sabah.gov.my/" target="_blank" style="color: blue;text-decoration: underline;"><b>JTU</b></a>
                                -> e-Service -> e-Commerce</b>
                            </p>
                        </li>
                        <li>
                            <p>SabahPay App</p>
                            <p>Muat turun aplikasi SabahPay dari Google Play Store atau Apple Store. Buka SabahPay Apps,
                                masuk ke <b>Payments</b> > <b>Public Sector</b> > <b>Jabatan Tanah & Ukur</b> > <b>Quit
                                    Rent</b>, kemudian ikut arahan yang dipamer</p>
                            
                            <p>Penyata akan dikemaskini dalam tempoh 3 hari bekerja</p>
                        </li>
                        <!--<li>
                            <p>JTU E-Commerce</p>
                            <p>Sila layani website E-Service <a href="https://eservice.hq.jtu.my/SLP/index.cfm" target="_blank" style="color: blue;text-decoration: underline;"><b>JTU E-Service</b></a>
                                kemudian pilih dan click icon e-Commerce, seterusnya pilih cara login. Sekiranya anda tidak mempunyai login, 
                                anda boleh mendaftar dengan menggunakan email.
                            </p>
                        </li> -->
                    </ol>
                    <p>atau</p>
                </li>
                <li class='mt-3'>
                    <p>Membuat pembayaran di Kaunter Cukai di mana-mana Pejabat Tanah Daerah yang berdekatan, Ibu
                        Pejabat atau di Kaunter Cukai UTC. Pembayaran sewa tanah boleh dibuat dalam bentuk:</p>
                    <ol class='list-decimal pl-5' >
                        <li>Tunai (Tidak melebihi RM10,000).</li>
                        <li>Cashier Order, Bank Draft, Postal Order dan Money Order. (Nama penerima : Pengarah Tanah dan
                            Ukur (pembayaran di Ibu Pejabat) atau Penolong Pemungut Hasil Tanah (pembayaran dibuat di
                            pejabat Tanah Daerah).</li>
                    </ol>
                </li>
            </ul>

            <p class='mt-3'>Untuk makluman, sewa tanah tahunan yang dikenakan hendaklah dibayar pada 01hb. Januari
                setiap tahun. Kegagalan melunaskan tunggakan sewa tanah boleh dikenakan bayaran yuran notis tuntutan
                sebanyak RM 30.00 dan surcaj (surcharge) sebanyak 24% dari jumlah amaun tunggakan selepas tamat tempoh
                notis tuntutan. Tindakan melelong tanah akan diambil sekiranya tuan/puan masih gagal membuat pembayaran.
            </p>

            <p class='mt-3'>Sila pastikan anda mendapat resit pembayaran daripada kaunter berkenaan atau mencetak
                sendiri resit pembayaran jika dibuat melalui internet.</p>
            <!-- <p>Bayaran yang dibuat selepas 26-10-2021 tidak diambil kira dalam surat ini.</p> -->

            <p class='mt-3 text-red-500'>Nota: Notis peringatan hanya sah laku pada tahun semasa</p>
        </div>

        <!-- <IconButton @click="print" :label="$t('print')"
            svg="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" /> -->

        <!-- <IconButton v-show="totaloutstanding > 10" @click = "pay" :label = "$t('pay')"
            svg = "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/> -->
    </div>
</template>

<script>
import router from '@/router'
import Title from '@/components/Title'
import IconButton from '@/components/IconButton'
import IconButtonGreen from '@/components/IconButtonGreen'
import axios from 'axios'
import config from '@/config'

export default {
    name: "Arrears",
    data: () => {
        return {
            titleno: "",
            currentyear: 0,
            lastpaymentdate: "",
            curdue: 0,
            cfdue: 0,
            cfnoticefee: 0,
            cfsurcaj: 0,
            payments: [],
            receipts: [],
            rents: [],
            showpayment: false,
            showdetail: false,
            statementpdfurl: config.STATEMENTPDF_URL,
            receiptpdfurl: config.RECEIPTPDF_URL,
            totaloutstanding: 0,
            paymentmoduleenabled: true,
            paymentmoduledisablemessage: "** Payment temporary disabled **",
        }
    },
    mounted: function () {
        this.titleno = this.$route.query.titleno

        axios.get(config.FPX_SERVER_STATUS_URL)
            .then(res => {
                if (res.data.success) {
                  this.paymentmoduleenabled = res.data.status;
                  if (res.data.message) {
                    this.paymentmoduledisablemessage = res.data.message;
                  }
                }
            })

        axios.get(config.QUERY_URL + "?titleno=" + this.titleno)
            .then(res => {
                if (res.data.status === 'OK') {
                    
                    this.currentyear = res.data.currentyear;
                    this.curdue = res.data.curdue;
                    this.cfdue = res.data.cfdue;
                    this.cfnoticefee = res.data.cfnoticefee;
                    this.cfsurcaj = res.data.cfsurcaj;
                    this.payments = res.data.data.sort((a, b) => {
                        let d1 = a.tdate.substring(6) + a.tdate.substring(3, 5) + a.tdate.substring(0, 2);
                        let d2 = b.tdate.substring(6) + b.tdate.substring(3, 5) + b.tdate.substring(0, 2);

                        return d2 - d1;
                    });

                    this.payments = res.data.data.sort((a, b) => {
                        let d1 = a.tdate.substring(6) + a.tdate.substring(3, 5) + a.tdate.substring(0, 2);
                        let d2 = b.tdate.substring(6) + b.tdate.substring(3, 5) + b.tdate.substring(0, 2);

                        return d2 - d1;
                    });

                    this.receipts = res.data.receiptData.sort((a, b) => {
                        let d1 = a.tdate.substring(6) + a.tdate.substring(3, 5) + a.tdate.substring(0, 2);
                        let d2 = b.tdate.substring(6) + b.tdate.substring(3, 5) + b.tdate.substring(0, 2);

                        return d2 - d1;
                    });

                    this.rents = res.data.rentData;
            
                    if (this.payments[0] != null) {
                        this.lastpaymentdate = this.payments[0].tdate.replace("/","-").replace("/","-");
                    } else {
                        this.lastpaymentdate = "00-00-0000";
                    };
                }
            })
    },
    components: {
        Title,
        IconButton,
        IconButtonGreen,
    },
    computed: {
        tobepaid: function () {
            return Math.round((this.cfdue + this.curdue - this.totalpaid) * 100) / 100;
        },
        totalpaid: function () {
            return this.payments.filter(p => p.paycd1 === "1").map(p => p.amt)
                .reduce((s, a) => s + a, 0);
        },
        totalpaidnoticefee: function () {
            return this.payments.filter(p => p.paycd1 === "2").map(p => p.amt)
                .reduce((s, a) => s + a, 0);
        },
        totalpaidsurcaj: function () {
            return this.payments.filter(p => p.paycd1 === "3").map(p => p.amt)
                .reduce((s, a) => s + a, 0);
        },
        tobepaidnoticefee: function () {
            return Math.round((this.cfnoticefee - this.totalpaidnoticefee) * 100) / 100;
        },
        tobepaidsurcharge: function () {
            return Math.round((this.cfsurcaj - this.totalpaidsurcaj) * 100) / 100;
        },
        tobepaidoutstanding: function () {

            let tobepaidqr = (this.cfdue + this.curdue) - this.totalpaid;
            let tobepaidnf = (this.cfnoticefee - this.totalpaidnoticefee);
            let tobepaidsc = (this.cfsurcaj - this.totalpaidsurcaj);

            if(this.tobepaidnf > 0){
                this.totaloutstanding += (tobepaidnf);
            }
            if(this.tobepaidsc > 0){
                this.totaloutstanding += (tobepaidsc);
            }

            this.totaloutstanding = (tobepaidqr);

            return this.totaloutstanding;
        },
    },
    methods: {
        print: () => {
            window.print()
        },
        pay: function () {
            router.push({
                path: 'payment',
                query: {
                    titleno: this.titleno,
                    // totaldue: this.tobepaid.toFixed(2),
                    quitrentdue: this.tobepaid.toFixed(2),
                    noticefeedue: this.tobepaidnoticefee.toFixed(2),
                    surchargedue: this.tobepaidsurcharge.toFixed(2)
                }
            })
        },
        viewReceipt: function (rctnum) {
            router.push({
                path: 'receipt',
                query: {
                    rctnum: rctnum,
                    titleno: this.titleno,
                    showspinner: true
                }
            })
        },
    },
}
</script>